<template>
<aheader/>
<section class="section">
  <div class="columns">
    <div class="column is-8 is-offset-2">
      Step
      <span class="icon has-text-success is-big">
        <i class="fa-solid fa-1 fa-lg"></i>
      </span>
      <progress class="progress is-success" value="20" max="100"></progress>
    </div>
  </div>
  <section class="section">
    <div class="columns">
      <div class="column is-9 is-offset-1">
        <div v-for="item, index in this.$store.state.cartList" :key='index'>
          <div class="columns is-centered">
            <div class="column is-3 is-offset-1">
              <p class="image is-64x64">
                <img :src="item.icon">
              </p>
            </div>
            <div class="column is-6">
              <div class="columns is-centered">
                <div class="column is-half">
                  <p class="title is-5">
                    {{item.name}}
                    <br>
                    {{item.kind}}
                  </p>
                </div>
                <div class="column is-half">
                  <p class="subtitle is-6 mt-2">
                    価格：{{item.value}}円
                  </p>
                </div>
              </div>
            </div>
            <div class="column is-2">
              <div class="level is-mobile">
                <div class="level-item">
                  <button class="button is-small has-text-weight-bold" @click="reduce(index)" :disabled="item.count == 1">－</button>
                </div>
                <div class="level-item">
                  {{item.count}}
                </div>
                <div class="level-item">
                  <button class="button is-small has-text-weight-bold" @click="add(index)">＋</button>
                </div>
          </div>
            </div>
            <div class="column is-1">
              <button class="button" @click="deleteItems(item.id)">
                削除
              </button>
            </div>
          </div>
          <hr>
        </div>
      </div>
    </div>
    <div class="columns is-vcentered">
      <div class="column is-4 is-offset-3">
        <div>
          <p class="title is-4">
            小計（税込 ）{{totalPrices}}円
          </p>
        </div>
      </div>
      <div class="column is-3">
        <button class="button has-background-info-dark has-text-white-ter" @click="pushPage('20')">
          <span>
            レジにすすむ
          </span>
          <span class="icon is-small">
            <i class="fa-solid fa-arrow-right"></i>
          </span>
        </button>
      </div>
    </div>
  </section>
</section>
<afooter/>
</template>
<script>
export default {
  data() {
    return {
      cartList: this.$store.state.cartList
    }
  },
  mounted() {
    this.scrollTop()
  },
  computed: {
    totalPrices() {
      return this.$store.getters.totalPrice.toString().replace(/\B(?=(\d{3})+$)/g, ',');
    }
  },
  methods: {
    scrollTop() {
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      })
    },
    deleteItems(id) {
      //alert(id)
      this.$store.commit('deleteItem', id)
    },
    add(index) {
      this.$store.commit('addItem', index);
    },
    reduce(index) {
      this.$store.commit('reduceItem', index);
    },
    pushPage(page) {
      this.$router.push(page);
    }
  }
}
</script>
